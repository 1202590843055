<template>
  <div>
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-form
        novalidate
        @submit.prevent="onSubmit"
      >
        <b-card>
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.role_title')"
                vid="title"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.role_title')"
                >
                  <b-form-input
                    v-model="form.title"
                    :placeholder="$t('labels.title')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="match-height">
          <b-col
            v-for="(item,index) in permissionsList"
            :key="index"
            md="6"
            lg="4"
          >
            <b-card no-body>
              <b-card-body>
                <b-card-title class="text-capitalize d-flex align-items-center">
                  <b-form-checkbox
                    v-model="selectedAll"
                    :value="index"
                    size="md"
                    @change="onChangeSelect(index)"
                  />
                  {{ indexLabel(index) }}
                </b-card-title>

                <b-card-text>
                  <b-form-group>
                    <b-form-checkbox
                      v-for="child in item"
                      :key="child.id"
                      v-model="selected"
                      :value="child.id"
                      class="mb-1"
                    >
                      <span>{{ indexLabel(child.title) }}</span>
                      <feather-icon
                        v-b-tooltip.hover.v-primary
                        :title="child.description"
                        variant="outline-primary"
                        icon="InfoIcon"
                        size="16"
                      />
                    </b-form-checkbox>
                  </b-form-group>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-card>
          <b-row>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      selected: [],
      permissionsList: [],
      selectedAll: [],
      form: {
        title: null,
        permissions: [],
      },
    }
  },
  watch: {
    selected($val) {
      this.form.permissions = $val
      this.checkAllSelected()
    },
  },
  created() {
    this.loadPermissions()
    if (this.isEdit) {
      this.loadRole()
    }
  },
  methods: {
    indexLabel(text) {
      return text?.replace(/_/g, ' ')
    },
    onChangeSelect(item) {
      if (this.selectedAll.includes(item)) {
        const list = _.map(this.permissionsList[item], 'id')
        list.forEach(x => {
          if (this.selected.indexOf(x) < 0) {
            this.selected.push(x)
          }
        })
        this.checkForSelectAll(item)
      } else {
        const list = _.map(this.permissionsList[item], 'id')
        list.forEach(x => {
          this.selected.splice(this.selected.indexOf(x), 1)
        })
      }
    },
    checkForSelectAll(item) {
      const arr1 = this.form.permissions
      const arr2 = _.map(this.permissionsList[item], 'id')
      const allFounded = _.every(arr2, ai => arr1.includes(ai))
      if (allFounded) {
        if (!this.selectedAll.includes(item)) {
          this.selectedAll.push(item)
        }
      } else {
        this.selectedAll.splice(this.selectedAll.indexOf(item), 1)
      }
    },
    checkAllSelected() {
      const arr1 = this.form.permissions
      Object.keys(this.permissionsList)
        .forEach(item => {
          const arr2 = _.map(this.permissionsList[item], 'id')
          const allFounded = _.every(arr2, ai => arr1.includes(ai))
          if (allFounded && !this.selectedAll.includes(item)) {
            this.selectedAll.push(item)
          }
          if (!allFounded && this.selectedAll.includes(item)) {
            this.selectedAll.splice(this.selectedAll.indexOf(item), 1)
          }
        })
    },
    loadPermissions() {
      this.axios.get('/permissions?per_page=-1')
        .then(res => {
          const { data } = res.data
          this.permissionsList = _.groupBy(data, 'page')
        })
    },
    loadRole() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const $data = res.data.data
          this.selected = $data.permissions.map(x => x.id)
          this.form = {
            title: $data.title,
            permissions: this.selected,
          }
        })
    },
  },
}
</script>
<style lang="scss">
.custom-checkbox {
  .custom-control-label {
    > span {
      margin-right: 15px;
    }
  }
}
</style>
